<template>
  <div class="check_list_reports">
      <div class="check_list_reports-header">
        <el-select style="width:40%;" v-model="selected_time" class="m-2" placeholder="הצג לפי" size="large">
            <el-option
            v-for="time in time_options"
            :key="time.value"
            :label="time.label"
            :value="time.value"
            />
        </el-select>
        <el-select style="width:40%; margin-right:5px;" multiple collapse-tags v-model="selected_role" class="m-2" placeholder="תפקיד" size="large">
            <el-option
            v-for="role in role_options"
            :key="role"
            :value="role"
            />
        </el-select>
        <i @click="init" class="material-icons refresh-icon">refresh</i>
      </div>
      <div class="check_list_reports-filters-header">
          <small>סינון תוצאות</small>
      </div>
      <div class="check_list_reports-filters">
        <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
        <input type="date" style="margin-right:5px;"  v-model="fromDate" placeholder="מתאריך">
        <input type="date" style="margin-right:5px;" v-model="toDate" placeholder="עד תאריך">
      </div>
      <div v-if="platform=='desktop' || platform=='tablet'" v-loading="is_pending" class="check_list_reports-content">
            <table>
                <tr>
                    <th>צ'ק ליסט</th>
                    <th>תפקיד</th>
                    <th>תיאור</th>
                    <th>סומן ע"י</th>
                    <th>סומן בתאריך</th>
                    <th>סניף</th>
                    <th>פעולות</th>
                </tr>
                <h1 v-if="slice_submits.length==0" style="text-align:center; color:#333;">לא קיימות רשומות</h1>
                <template v-for="list in slice_submits" :key="list.check_list_uid">
                    <tr>
                        <td>{{list.name}}</td>
                        <td>{{list.role}}</td>
                        <td>{{list.description}}</td>
                        <td>{{list.user_name}}</td>
                        <td>{{new Date(list.start_time._seconds*1000).toLocaleDateString('he')}}</td>
                        <td>{{list.branche}}</td>
                        <td>
                            <i @click="handle_show_preview(list)" class="material-icons icon eye-icon">visibility</i>
                            <i @click="handle_delete(list)" class="material-icons icon delete-icon">delete</i>
                        </td>
                    </tr>
                </template>  
            </table>
      </div>
       <div v-if="platform=='mobile'" v-loading="is_pending" class="check_list_reports-content">
           <h1 v-if="slice_submits.length==0" style="text-align:center; color:#fff;">לא קיימות רשומות</h1>
            <template v-for="list in slice_submits" :key="list.check_list_uid">
                <table style="margin-bottom:5px;">
                    <tr>
                        <th style="width:30%;">צ'ק ליסט</th>
                        <td>{{list.name}}</td>
                    </tr>
                    <tr>
                         <th>תפקיד</th>
                        <td>{{list.role}}</td>
                    </tr>
                    <tr>
                        <th>תיאור</th>
                        <td>{{list.description}}</td>
                    </tr>
                    <tr>
                        <th>סומן ע"י</th>
                        <td>{{list.user_name}}</td>
                    </tr>
                    <tr>
                        <th>סומן בתאריך</th>
                        <td>{{new Date(list.start_time._seconds*1000).toLocaleDateString('he')}}</td>
                    </tr>
                    <tr>
                        <th>סניף</th>
                        <td>{{list.branche}}</td>
                    </tr>
                    <tr>
                        <th>פעולות</th>
                        <td>
                          <i @click="handle_show_preview(list)" class="material-icons icon eye-icon">visibility</i>
                          <i @click="handle_delete(list)" class="material-icons icon delete-icon">delete</i>
                      </td>
                    </tr>
                </table>
            </template> 
       </div>
      <div class="check_list_reports-pagination">
        <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="filter_by_search.length"
        />
      </div>

      <PreviewSubList @close="show_preview=false" v-if="show_preview" :check_list="selected_check_list" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_submits_check_list,delete_submit_from_db,platform} from '../../../Scripts/Admin/scripts'
import {alert_confirm} from '../../../../../../Methods/Msgs'
import PreviewSubList from '../Check_lists_reports/PreviewSubList.vue'

import { computed } from '@vue/runtime-core'
export default {
    components:{PreviewSubList},
    setup(){
        
        const show_preview = ref(false)
        const selected_check_list = ref(null)

        const search = ref('')
        const submits = ref([])
        
        const is_pending = ref(false)
        
        const fromDate = ref('')

        const toDate = ref('')

        // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date._seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        const handle_show_preview = (list)=>{
            selected_check_list.value = list
            show_preview.value = true
        }   

        const filter_by_search = ref(computed(()=>{
            if(search.value == '') {
                if(fromDate.value && toDate.value){
                    return submits.value.filter(submit=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(submit.start_time) &&
                        stringTodate(toDate.value)>=timestampToDate(submit.start_time) ){
                            return submit
                        }
                    })
                }
                return submits.value;
            }
            else{
                let results= submits.value.filter(submit=>{
                    if(new Date(submit.start_time._seconds * 1000).toLocaleDateString('he').includes(search.value)) return submit
                    if (submit.name.includes(search.value)) return submit;
                    if (submit.role.includes(search.value)) return submit;
                    if (submit.description.includes(search.value)) return submit;
                    if (submit.user_name.includes(search.value)) return submit;
                 
                })
                if(fromDate.value && toDate.value){
                    return results.filter(submit=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(submit.start_time) &&
                        stringTodate(toDate.value)>=timestampToDate(submit.start_time) ){
                            return submit
                        }
                    })
                }else{
                    return results
                }
            }
        }))

        const selected_time = ref('14')
        const selected_role = ref([])

        const time_options = ref([
            {
                value:'14',
                label:'שבועיים אחרונים'
            },
            {
                value:'30',
                label:'חודש אחרון'
            },
            {
                value:'60',
                label:'חודשיים אחרונים'
            },
            {
                value:'350',
                label:'שנה אחרונה'
            },
            {
                value:'הכל',
                label:'כל הזמנים'
            },
        ])
        
        const role_options = ref([
            'קבטים',
            'מנהלי סניפים',
            'מנהלי רשת',
            'אחר'
        ])
        
        const pagination = ref({
            current_page:1,
            number_per_page:15,
        })

        const slice_submits = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))

        const handle_delete = (list) => {
            alert_confirm(`למחוק את "${list.name}" בתפקיד "${list.role}" אשר סומן ע"י ${list.user_name}?`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await delete_submit_from_db(list)
                    const index = submits.value.findIndex(sub=>sub.check_list_uid == list.check_list_uid)
                    index!=-1 && submits.value.splice(index,1)
                }
            })
        }
        const init = async()=>{
            let res
            is_pending.value = true
            if(selected_time.value!='הכל'){
                res =  await get_submits_check_list(Number(selected_time.value),selected_role.value)
            }else{
                res =  await get_submits_check_list(null,selected_role.value)
            }
           submits.value = res
           is_pending.value = false
        }

        init()
        return{
            search,
            submits,
            pagination,
            slice_submits,
            time_options,
            selected_time,
            init,
            is_pending,
            selected_role,
            role_options,
            fromDate,
            toDate,
            filter_by_search,
            handle_delete,
            show_preview,
            handle_show_preview,
            selected_check_list,
            platform,
        }
    }
}
</script>

<style scoped>
    .check_list_reports{
        width: 100%;
        height: 100%;
    }
    .check_list_reports-header,.check_list_reports-filters{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .check_list_reports-filters-header{
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .refresh-icon{
        cursor: pointer;
        font-size: 25px;
        transition: all 0.2s ease;
        user-select: none;
        color: var(--warning);
        margin-right: 10px;
        font-size: 30px;
    }
    .check_list_reports-content{
        width: 100%;
        height: calc(100% - 170px);
        overflow-y: auto;
    }
    .check_list_reports-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--purple);
        color: #fff;
    }

    .icon{
        font-size: 25px;
        margin: 0 5px;
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
    }
    .eye-icon{
        color: var(--purple);
    }

    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    
</style>