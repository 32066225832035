<template>
  <div class="check-lists-informations">
      <div class="check-lists-informations-header">
          <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
          <i @click="handle_refersh" class="material-icons refresh-icon">refresh</i>
      </div>
      <div v-loading="is_pending" v-if="platform=='desktop' || platform=='tablet'" class="check-lists-informations-content">
          <table>
              <tr>
                  <th style="cursor:pointer;" @click="handleSortTable('תאריך', !selectedSorted.order)">נוצר בתאריך</th>
                  <th style="cursor:pointer;" @click="handleSortTable('שם', !selectedSorted.order)">שם</th>
                  <th style="cursor:pointer;" @click="handleSortTable('תפקיד', !selectedSorted.order)">תפקיד</th>
                  <th style="cursor:pointer;" @click="handleSortTable('דיווח למייל', !selectedSorted.order)">דיווח לדוא"ל</th>
                  <th style="cursor:pointer;" @click="handleSortTable('תיאור', !selectedSorted.order)">תאור</th>
                  <th>פעולות</th>
                  <th></th>
              </tr>
              <h1 v-if="sorted_records.length==0" style="text-align:center; color:#333;">לא קיימות רשומות</h1>
              <template v-for="(list,index) in sorted_records" :key="list.uid">
                <tr>
                    <td>{{new Date(list.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                    <td>{{list.name}}</td>
                    <td>{{list.role}}</td>
                    <td>{{list.email_report}}</td>
                    <td>{{list.description}}</td>
                    <td>
                        <i @click="handle_edit_check_list(list)" class="material-icons edit-icon">edit</i>
                        <i @click="handle_delete_check_list(list,index)" class="material-icons delete-icon">delete</i>
                    </td>
                    <td>
                        <i :id="`line-${index}`" @click="handle_expand_line(index)" class="material-icons expand-icon">expand_more</i>
                    </td>
                    
                </tr>
                <tr v-if="indicator==index">
                    <td colspan="7">
                        <div class="details slide-in-right">
                            <table>
                                <tr>
                                    <th>תיאור</th>
                                    <th>אפשרות הוספת תמונה</th>
                                    <th>נדרש תמונה חובה</th>
                                    <th>סוג תגובת משתמש</th>
                                </tr>
                                <template v-for="(sub,sub_index) in list.check_lists" :key="sub_index">
                                    <tr>
                                        <td>{{sub.desc}}</td>
                                        <td v-if="sub.img">כן</td>
                                        <td v-else>לא</td>
                                        <td v-if="sub.must_image">כן</td>
                                        <td v-else>לא</td>
                                        <td v-if="sub.type=='bool'">כן/לא</td>
                                        <td v-if="sub.type=='str'">מלל חופשי</td>
                                    </tr>
                                </template>    
                            </table>
                        </div>
                    </td>
                </tr>
              </template>  
          </table>
      </div>
      <div v-loading="is_pending" v-if="platform=='mobile'" class="check-lists-informations-content">
          <h1 v-if="sorted_records.length==0" style="text-align:center; color:#333;">לא קיימות רשומות</h1>
          <template v-for="(list,index) in sorted_records" :key="list.uid">
              <table style="margin-bottom:5px;">     
                <tr>
                    <th style="width:30%;">נוצר בתאריך</th>
                    <td>{{new Date(list.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                </tr>
                <tr>
                    <th>שם</th>
                    <td>{{list.name}}</td>
                </tr>
                <tr>
                    <th>תפקיד</th>
                    <td>{{list.role}}</td>
                </tr>
                <tr>
                    <th>דיווח לדוא"ל</th>
                    <td>{{list.email_report}}</td>
                </tr>
                <tr>
                    <th>תאור</th>
                    <td>{{list.description}}</td>
                </tr>
                <tr>
                    <th>פעולות</th>
                    <td>
                        <i @click="handle_edit_check_list(list)" class="material-icons edit-icon">edit</i>
                        <i @click="handle_delete_check_list(list,index)" class="material-icons delete-icon">delete</i>
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td>
                        <i :id="`line-${index}`" @click="handle_expand_line(index)" class="material-icons expand-icon">expand_more</i>
                    </td>
                </tr>
                <tr v-if="indicator==index">
                    <td colspan="2">
                        <div class="details slide-in-right">
                            <table>
                                <tr>
                                    <th>תיאור</th>
                                    <th>אפשרות הוספת תמונה</th>
                                    <th>נדרש תמונה חובה</th>
                                    <th>סוג תגובת משתמש</th>
                                </tr>
                                <template v-for="(sub,sub_index) in list.check_lists" :key="sub_index">
                                    <tr>
                                        <td>{{sub.desc}}</td>
                                        <td v-if="sub.img">כן</td>
                                        <td v-else>לא</td>
                                        <td v-if="sub.must_image">כן</td>
                                        <td v-else>לא</td>
                                        <td v-if="sub.type=='bool'">כן/לא</td>
                                        <td v-if="sub.type=='str'">מלל חופשי</td>
                                    </tr>
                                </template>    
                            </table>
                        </div>
                    </td>
                </tr>
              </table>
          </template> 
      </div>
      <div v-if="show_edit" class="edit-check_list">
          <div class="edit-check_list-wrapper">
              <div class="edit-check_list-wrapper-header">
                  <h2>עריכת צק ליסט נבחר</h2>
              </div>
              <div class="edit-check_list-wrapper-content">
                    <CreateNewCheckList @close="handle_close_edit" :edit_data="check_list_edit" />
              </div>
          </div>
          <div @click="show_edit=false" class="close-btn">
              <i class="material-icons">close</i>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_check_lists_form_db,delete_specific_check_list_db,platform} from '../../Scripts/Admin/scripts'
import {alert_confirm} from '../../../../../Methods/Msgs'
import CreateNewCheckList from './CreateNewCheckList.vue'
import { computed } from '@vue/runtime-core'
export default {
    components:{CreateNewCheckList},
    setup(){
        
        const show_edit = ref(false)
        const check_list_edit = ref(null)
        
        const is_pending= ref(false)
        const indicator = ref(null)
        const search = ref('')
        const check_lists = ref([])
        
        const filter_by_search = ref(computed(()=>{
            return check_lists.value.filter(list=>{
                if(new Date(list.created_at.seconds*1000).toLocaleDateString('he').includes(search.value)) return list
                if(list.name.includes(search.value)) return list
                if(list.role.includes(search.value)) return list
                if(list.description.includes(search.value)) return list
            })
        }))

        const selectedSorted = ref({
            name: "",
            order: false
        });

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };

        const sorted_records = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_by_search.value;
            if (selectedSorted.value.name == "תאריך") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.created_at.seconds*1000) >  new Date(b.created_at.seconds*1000)
                        ? 1
                        : new Date(b.created_at.seconds*1000)  > new Date(a.created_at.seconds*1000) 
                        ? -1
                        : 0;
                    }
                    return new Date(a.created_at.seconds*1000) > new Date(b.created_at.seconds*1000) 
                    ? -1
                    : new Date(b.created_at.seconds*1000)   > new Date(a.created_at.seconds*1000)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.name >  b.name
                        ? 1
                        : b.name  > a.name 
                        ? -1
                        : 0;
                    }
                    return a.name > b.name 
                    ? -1
                    : b.name > a.name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "תפקיד") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.role >  b.role
                        ? 1
                        : b.role  > a.role 
                        ? -1
                        : 0;
                    }
                    return a.role > b.role 
                    ? -1
                    : b.role > a.role
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "דיווח למייל") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.email_report >  b.email_report
                        ? 1
                        : b.email_report  > a.email_report 
                        ? -1
                        : 0;
                    }
                    return a.email_report > b.email_report 
                    ? -1
                    : b.email_report > a.email_report
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "תיאור") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.description >  b.description
                        ? 1
                        : b.description  > a.description 
                        ? -1
                        : 0;
                    }
                    return a.description > b.description 
                    ? -1
                    : b.description > a.description
                    ? 1
                    : 0;
                });
            }
        }));

        const handle_close_edit = async()=>{
            await init()
            show_edit.value = false
        }
        const handle_expand_line = (index)=>{
            if(indicator.value == index) indicator.value = null
            else indicator.value = index
        }

        const handle_refersh = async()=>{
            await init()
        }

        const handle_edit_check_list = (list)=>{
            console.log(list);
            check_list_edit.value = JSON.parse(JSON.stringify(list))
            show_edit.value = true

        }

        const handle_delete_check_list = (list,index)=>{
            alert_confirm(`למחוק את "${list.name}"?`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await delete_specific_check_list_db(list)
                    check_lists.value.splice(index,1)
                }
            })
        }

        const init = async()=>{
            is_pending.value = true
            check_lists.value = await get_all_check_lists_form_db()
            is_pending.value = false
        }

        init()
        return{
            handle_expand_line,
            handle_refersh,
            handle_delete_check_list,
            handle_edit_check_list,
            handle_close_edit,
            search,
            check_lists,
            indicator,
            show_edit,
            check_list_edit,
            is_pending,
            filter_by_search,
            handleSortTable,
            selectedSorted,
            sorted_records,
            platform,
        }
    }
}
</script>

<style scoped>
    .check-lists-informations{
        width: 100%;
        height: 100%;
    }
    .check-lists-informations-header{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .check-lists-informations-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow:auto;
    }
    .edit-check_list{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background: rgba(0, 0, 0, 0.9);
        padding:0 5px;
    }
    .edit-check_list-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        overflow:hidden;
        background: var(--main);
    }
    .edit-check_list-wrapper-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
    }
    .edit-check_list-wrapper-content{
        width: 100%;
        height: calc(100% - 50px);
    }
    .close-btn{
        position: absolute;
        width: 25px;
        height: 25px;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        z-index: 12;
        background: var(--danger);
    }

    
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--main);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    table tr .details{
        width: 100%;
        height: fit-content;
    }
    table tr .details th{
         background-color: var(--success);
    }
    .expand-icon,.delete-icon,.edit-icon,.refresh-icon{
        cursor: pointer;
        font-size: 25px;
        transition: all 0.2s ease;
        user-select: none;
    }

    .expand-icon.up{
        transform: rotate(-180deg);
    }

    .delete-icon{
        color: var(--danger);
    }
    .edit-icon{
        color: var(--warning);
        margin-left: 10px;
    }
    .refresh-icon{
        color: var(--warning);
        margin-right: 10px;
        font-size: 30px;
    }

    .slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }



</style>