<template>
  <div class="preview_sub_list">
      <div class="preview_sub_list-wrapper slide-in-top">
          <div class="preview_sub_list-wrapper-header">
              <h2>{{check_list.name}}</h2>
              <div class="close_btn">
                  <i @click="$emit('close')" class="material-icons">close</i>
              </div>
          </div>
          <div class="preview_sub_list-wrapper-content">
              <template v-for="(sub,index) in check_list.results" :key="index">
                  <table style="margin-bottom:5px">
                      <tr>
                        <th style="width:30%;">תאור</th>
                        <td>{{sub.desc}}</td>
                      </tr>
                      <tr>
                        <th style="width:30%;">תגובה</th>
                        <td>{{sub.answer}}</td>
                      </tr>
                      <tr v-if="sub.comment">
                        <th style="width:30%;">הערה</th>
                        <td>{{sub.comment}}</td>
                      </tr>
                      <template v-for="img in sub.imges_url" :key="img">
                        <tr>
                            <th style="width:30%;">תמונה</th>
                            <td>
                                <a :href="img" target="_blank"><img style="width:100px;" :src="img"></a>
                            </td>
                        </tr>
                     </template> 
                  </table>
               </template>   
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:['check_list'],
    setup(){
        
        return{}
    }
}
</script>

<style>
    .preview_sub_list{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .preview_sub_list-wrapper{
        width: 100%;
        max-width: 500px;
        height: 70%;
        background: #fff;
        border-radius: 10px;
    }
    .preview_sub_list-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        background: var(--purple);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0 0;
    }
    .close_btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        width: 25px;
        height: 25px;
        background: var(--danger);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
    }
    .preview_sub_list-wrapper-content{
        width: 100%;
        height: calc(100% - 50px);
        color: #333;
        padding: 5px;
        overflow-y: auto;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table:nth-child(even){background-color: #f2f2f2;}
    table:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        font-size: 20px;
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

 
    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>