<template>
  <div v-loading="is_pending" class="create-new-checkList">
      <div class="create-new-checkList-content">
        <div class="create-new-checkList-fields">
            <div class="create-new-checkList-field">
                <h2>שם <span style="color:red;">*</span></h2>
                <el-input v-model="values.name"  placeholder="שם הצ'ק ליסט" />
            </div>
            <div class="create-new-checkList-field">
                <h2>תיאור <span style="color:red;">*</span></h2>
                <el-input v-model="values.description" placeholder="תיאור הצ'ק ליסט" />
            </div>
            <div class="create-new-checkList-field">
                <h2>תפקיד <span style="color:red;">*</span></h2>
                <el-select style="width:100%;" v-model="values.role" class="m-2" placeholder="תפקיד" size="large">
                    <el-option
                    v-for="role in role_options"
                    :key="role"
                    :value="role"
                    />
                </el-select>
            </div>
            <div class="create-new-checkList-field checks">
                <h2>דיווח למייל <span style="color:red;">*</span></h2>
                <label style="margin-right:5px;">כן</label>
                <input v-model="values.email_report" value="כן" type="radio">
                <label style="margin-right:5px;">לא</label>
                <input v-model="values.email_report" value="לא" type="radio">
            </div>
            <div v-if="values.email_report=='כן'" class="create-new-checkList-field">
                <h2> דוא"ל לדיווח <span style="color:red;">*</span> <small>נא ללחוץ על מקש האנטר כדי להכניס כתובת</small></h2>
                <el-input @keyup.enter="handle_add_email" v-model="email_types" placeholder="כתובות אימייל" />
                {{email_types}}
            </div>
            <div v-if="values.email_report=='כן'" class="create-new-checkList-field emails_list">
                <template v-for="(email,index) in values.email_reports" :key="email">
                    <div class="email">
                        <i @click="handle_remove_email_from_list(index)" class="material-icons close-icon">close</i>
                        {{email}}
                    </div>
                </template>
            </div>
        </div>
        <template v-for="(list,index) in values.check_lists" :key="index">
            <div class="create-new-checkList-options">
                <div>
                    <h2>סוג <span style="color:red;">*</span></h2>
                    <el-select style="margin:0;" v-model="list.type" class="m-2" placeholder="בחירת סוג" size="large">
                        <el-option
                        v-for="item in type_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </div>
                <div>
                    <h2>תיאור <span style="color:red;">*</span></h2>
                    <el-input v-model="list.desc" style="width:80%;" placeholder="תיאור" />
                </div>
                <div class="row">
                    <input v-model="list.img" style="margin:0 5px;" type="checkbox">
                    <h2>תמונה</h2>
                    <input v-if="list.img" v-model="list.must_image" style="margin:0 5px;" type="checkbox">
                    <h2 v-if="list.img">חובה</h2>
                </div>
                <div @click="handle_add_option(list)">
                    <i class="material-icons plus-icon">add_circle</i>
                </div>
                <i v-if="index>0" @click="handle_remove_option(index)" class="material-icons remove-option ">close</i>
            </div>
        </template>
      </div>
      <div class="create-new-checkList-footer">
          <el-button v-if="!edit_data" @click="handle_submit" style="width:50%;" type="success">עדכן</el-button>
          <el-button v-if="edit_data" @click="handle_submit_edit"  style="width:50%;" type="warning">סיום עריכה</el-button>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {add_new_check_list_in_db,edit_specific_check_list_db} from '../../Scripts/Admin/scripts'
export default {
    props:['edit_data'],
    emits:['close'],
    setup(props,{emit}){
        const email_types = ref('')
        const is_pending = ref(false)
        const err_msg = ref('')

        const role_options = ref([
            'קבטים',
            'מנהלי סניפים',
            'מנהלי רשת',
            'אחר'
        ])

        const type_options = ref([
            {
                value:'bool',
                label:'כן/לא'
            },
            {
                value:'str',
                label:'טקסט חופשי'
            },
        ])
        const values = ref({
            created_at:new Date(),
            name:'',
            description:'',
            role:'',
            email_report:'',
            email_reports:[],
            check_lists:[
                {
                    type:'',
                    desc:'',
                    img:false,
                    must_image:false,
                    answer:'',
                    imges_url:[]
                }
            ]
        })
        const handle_add_email = () => {
            if(!values.value.email_reports?.includes(email_types.value) && email_types.value.includes('@')){
                values.value.email_reports.push(email_types.value)
            }else{
                if(!email_types.value) slide_pop_error(`אינך יכול לשלוח שדה ריק!`)
                if(email_types.value) slide_pop_error(`הזנת ${email_types.value}, מה שהוזן לא תואם לכתובת אימייל!`)
            }
            email_types.value = ''
        }
        const handle_remove_email_from_list = (index) => {
            values.value.email_reports.splice(index,1)
        }
        const handle_add_option = (list)=>{
            if(list.type && list.desc){
                values.value.check_lists.push({
                    type:'',
                    desc:'',
                    img:false,
                    must_image:false,
                    answer:'',
                    imges_url:[]
                })
            }else{
                slide_pop_error('עליך לבחור סוג ולהזין תיאור!')
            }
        }

        const handle_remove_option  = (index)=>{
            if(values.value.check_lists.length>1){
                values.value.check_lists.splice(index,1)
            }
        }

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם מלא'
                            return false
                        }
                        break;
                    case 'description':
                        if(!value){
                            err_msg.value = 'עליך להזין שם תיאור'
                            return false
                        }
                        break;
                    case 'role':
                        if(!value){
                            err_msg.value = 'עליך להזין תפקיד'
                            return false
                        }
                        break;
                    case 'email_report':
                        if(!value){
                            err_msg.value = 'עליך לסמן דיווח למייל'
                            return false
                        }
                        break; 
                    case 'email_reports':
                        if(values.value.email_report == 'כן'){
                            if(value.length==0){
                                err_msg.value = 'עליך להזין כתובת אימייל אחת לפחות לדיווח!'
                                return false
                            }
                        }
                        break; 
                }
            }
            for(const list of values.value.check_lists){
                if(!list.type || !list.desc){
                    console.log(list);
                    err_msg.value = 'עליך למלא סוג ותיאור בכל אופציה!'
                    return false
                }
            }

            return true
        }

        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                await add_new_check_list_in_db(values.value)
                is_pending.value = false
                alert('success','הצלחה','הטופס נשלח בהצלחה')
                .then(()=>{
                    reset()
                })

            }
        }

        const handle_submit_edit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                await edit_specific_check_list_db(values.value)
                alert('success','הצלחה',
                `הצ'ק ליסט "${values.value.name}" עודכן בהצלחה`)
                .then(()=>{
                    emit('close')
                })

            }
        }

        const reset = ()=>{
            values.value = {
               created_at:new Date(),
                name:'',
                description:'',
                role:'',
                email_report:'',
                email_reports:[],
                check_lists:[
                    {
                        type:'',
                        desc:'',
                        img:false,
                        must_image:false,
                        answer:'',
                        imges_url:[]
                    }
                ]
            }
        }

        const init = ()=>{
            if(props.edit_data){
                values.value = props.edit_data
            }
        }

        init()
        return{
            handle_add_option,
            handle_remove_option,
            handle_submit,
            values,
            type_options,
            err_msg,
            is_pending,
            handle_submit_edit,
            role_options,
            email_types,
            handle_add_email,
            handle_remove_email_from_list,
        }
    }
}
</script>

<style scoped>
    .create-new-checkList{
        width: 100%;
        height: 100%;
    }
    .create-new-checkList-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow:hidden;
        overflow-y: auto;
    }
    .create-new-checkList-fields{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: 1fr;

    }
    .create-new-checkList-field{
        width: 100%;
        margin-bottom: 5px;
        padding: 0 5px;
    }
    .create-new-checkList-field.emails_list{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-items: flex-end;
    }
    .email{
        width: max-content;
        height: max-content;
        padding: 5px;
        background: lightblue;
        border-radius: 5px;
        display: flex;
        align-items: center;
        color: #333;
        margin: 5px;
    }
    .close-icon{
        color: var(--danger);
        font-size: 18px;
        cursor: pointer;
    }
    .create-new-checkList-options{
        position: relative;
        width: 100%;
        height: 100px;
        background: whitesmoke;
        border-radius: 5px;
        display: flex;
        margin-bottom: 5px;
    }
    .create-new-checkList-options > div{
        width: calc(100% / 4);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
    }
    .create-new-checkList-options > div.row{
        flex-direction: row;
    }
    .plus-icon{
        font-size: 50px;
        cursor: pointer;
        user-select: none;
        color: var(--success);
    }
    
    @media screen and (max-width: 600px) {
        .create-new-checkList-fields{
            display: block;
        }
        .create-new-checkList-field.checks{
            display: flex;
            align-items: center;
        }
        label{
            margin: 5px;
        }

        .create-new-checkList-options{
            height: auto;
            flex-wrap: wrap;
        }
        .create-new-checkList-options > div{
            width: calc(100% / 2);
            height: 100%;
            padding: 5px;
        }
    
    }
    .create-new-checkList-footer{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 5px;
    }

    .remove-option{
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 10;
        user-select: none;
        cursor: pointer;
        color: var(--danger);
        font-weight: 500;
    }
</style>